"use client";
import { useContext, useEffect, useState } from "react";
import svgDownloadIcon from "./../Dashboard/images/arrow_downward_alt.svg";
import macIcon from "./../Dashboard/images/mac.png";
import windowsIcon from "./../Dashboard/images/windows.png";
import CircleInstaller from "./images/installer.png";
import CircleMacInstaller from "./images/mac-installer.png";
import "./portal.css";
import LoadingSpinner from "./LoadingSpinner";
import "./portal.css";

import { Tooltip, Modal, Button } from "flowbite-react";

import CircleLogo from "./../LoginPage/CircleLogo";
import { UserContext } from "../../../context/UserContext";
import { createAuthKey } from "../../../Utilities/createAuthKey";
import Header from "./Header";
import DownloadButtons from "../../Common/DownloadButtons";

export default function LoggedInUserPortal() {
  const [isLoadingSpinner, setIsLoadingSpinner] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [workspaceName, setWorkspaceName] = useState<string | null>(null);
  const [showAuthConsumeErrorModal, setShowAuthConsumeErrorModal] =
    useState<boolean>(false);

  const userContext = useContext(UserContext);
  const deepLink = async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem("accessToken");

    const email = localStorage.getItem("email");
    setEmail(email);

    if (accessToken) {
      try {
        const authKey = await createAuthKey(
          accessToken,
          userContext.user.workspace_id
        );

        if (!authKey) {
          console.log("Auth key is null or undefined: ", authKey);
          alert("Auth key is invalid.Cannot launch Circle App!");
          setIsLoading(false);
          return;
        }

        const { oneTimeUseAuthTokenId } = authKey;

        if (!oneTimeUseAuthTokenId) {
          console.log("Token is null or undefined: ", oneTimeUseAuthTokenId);
          alert("One Time Token is invalid. Cannot launch Circle App!");
          setIsLoading(false);
          return;
        }

        console.log("Deeplink electron authkey: ", oneTimeUseAuthTokenId);
        const deeplink = `circleapp://login?email=${email}&oneTimeUseAuthKey=${oneTimeUseAuthTokenId}`;
        console.log("Deeplink URL: ", deeplink);
        window.location.href = deeplink;
        setIsLoading(false);
      } catch (error) {
        console.error("Error creating auth key:", error);
        alert("An error occurred while creating the auth key.");
        setIsLoading(false);
      }
    } else {
      alert("Cannot launch Circle App. Access token is invalid or null!");
      console.log("accessToken is null: ", accessToken);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const authConsumeSuccess = await userContext.config();
      if (!authConsumeSuccess) {
        console.log("authConsumeSuccess is false");
        setShowAuthConsumeErrorModal(true);
      }
      setIsLoadingSpinner(false);

      const email = localStorage.getItem("email");
      setEmail(email);
      const workspaceNameParam = localStorage.getItem("workspaceName");
      setWorkspaceName(workspaceNameParam);
    };

    fetchData();
  }, []);

  const closeTab = () => {
    window.close();
  };

  return (
    <>
      {isLoadingSpinner ? (
        <LoadingSpinner text="Loading..." />
      ) : (
        <>
          <div className="w-full h-screen ">
            <Header />
            <div className="flex flex-col items-center justify-center p-4  mx-auto">
              <CircleLogo height={80} width={80} />
              <p className="text-primary-500 text-3xl font-bold">Circle App</p>
              <div className="flex gap-1 mt-4 p-2">
                <div className="flex items-center justify-center gap-2 bg-r">
                  <p className=" text-sm font-medium text-primary-500 p-2 bg-primary-50 rounded-md">
                    {workspaceName}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-2 rounded">
                  <p className="text-sm font-medium text-primary-500 p-2 bg-primary-50 rounded-md">
                    {email}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid grid-rows-3 auto-cols-fr gap-4 place-items-center p-8 max-w-[80vw] min-w-[400px] mx-auto">
              <div className="bg-gray-100 shadow rounded p-4 w-full min-w-[400px] h-full flex flex-col items-center justify-center relative">
                <div className="absolute top-2 left-2 bg-gray-700 h-8 w-8 flex items-center justify-center rounded-md shadow-md">
                  <p className="font-semibold text-white">1</p>
                </div>
                <p className="font-semibold text-gray-500 text-center">
                  First time user? Download the Circle App
                </p>
                <p className="italic text-sm text-gray-500 text-center p-2">
                  Circle app already installed? Skip to{" "}
                  <span className="font-bold">step 3</span>
                </p>
                <div className="py-2">
                  <DownloadButtons buttonClassName="bg-sky-100 w-[100px] border border-gray-300 hover:border-white hover:bg-white border-dashed" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 w-full">
                <div className="bg-gray-100 shadow rounded p-4 w-full h-full flex flex-col items-center justify-center relative">
                  <div className="absolute top-2 left-2 bg-gray-700 h-8 w-8 flex items-center justify-center rounded-md shadow-md">
                    <p className="font-semibold text-white">2</p>
                  </div>
                  <div className="text-center">
                    <p className="font-semibold text-gray-500 text-center">
                      Install on Mac
                    </p>
                    <p className="italic text-gray-600 text-center font-bold">
                      Please return to this page for Step 3
                    </p>
                  </div>
                  <div className="py-2">
                    <img
                      className="h-[100px] object-contain"
                      src={CircleMacInstaller}
                      alt="Circle Mac Installer"
                    />
                  </div>
                </div>
                <div className="bg-gray-100 shadow rounded p-4 w-full h-full flex flex-col items-center justify-center relative">
                  <div className="absolute top-2 left-2 bg-gray-700 h-8 w-8 flex items-center justify-center rounded-md shadow-md">
                    <p className="font-semibold text-white">2</p>
                  </div>
                  <div className="text-center">
                    <p className="font-semibold text-gray-500 text-center">
                      Install on Windows
                    </p>
                    <p className="italic text-gray-600 text-center font-bold">
                      Please return to this page for Step 3
                    </p>
                  </div>
                  <div className="py-2">
                    <img
                      className="h-[100px] object-contain"
                      src={CircleInstaller}
                      alt="Circle Windows Installer"
                    />
                  </div>
                </div>
              </div>
              <div className="bg-gray-100 shadow rounded p-10 w-full min-w-[400px] h-full flex flex-col items-center justify-center relative">
                <div className="absolute top-2 left-2 bg-gray-700 h-8 w-8 flex items-center justify-center rounded-md shadow-md">
                  <p className="font-semibold text-white">3</p>
                </div>
                <p className="font-semibold text-gray-500 text-center py-4">
                  Open Circle Desktop App
                </p>
                <button
                  onClick={deepLink}
                  className={`w-60 text-white bg-primary-500 rounded-lg text-sm px-5 py-2.5 me-2 mb-2 ${
                    isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-primary-700 focus:ring-4 focus:ring-primary-300"
                  }`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center gap-2 text-sm font-medium">
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <p>Launching...</p>
                    </div>
                  ) : (
                    <div className="text-center text-white text-sm font-medium leading-5">
                      Launch Circle App
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        show={showAuthConsumeErrorModal}
        onClose={() => {}}
        popup
        size="md"
        dismissible={false}
      >
        <Modal.Body>
          <div className="text-center">
            <h3 className="mb-5 pt-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Token expired. Please reselect the workspace
            </h3>
            <div className="flex justify-center gap-4">
              <Button color="failure" onClick={closeTab}>
                Close Tab
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}















