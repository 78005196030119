import { Dropdown } from "flowbite-react";
import {
  MAC_APP_DOWNLOAD_URL,
  WINDOWS_APP_DOWNLOAD_URL,
} from "../../Constants/URLConstants";
import svgDownloadIcon from "../Content/Dashboard/images/arrow_downward_alt.svg";
import macIcon from "../Content/Dashboard/images/mac.png";
import windowsIcon from "../Content/Dashboard/images/windows.png";

interface DownloadButtonsProps {
  containerClassName?: string;
  buttonClassName?: string;
}

export default function DownloadButtons({
  containerClassName = "",
  buttonClassName = "",
}: DownloadButtonsProps) {
  return (
    <div className={`flex items-center justify-center ${containerClassName}`}>
      <a
        title="Download for Mac"
        className="w-full flex justify-evenly cursor-pointer bg-sky-100 items-center rounded-xl p-3"
        href={MAC_APP_DOWNLOAD_URL}
      >
        <div
          className={`w-24 rounded-lg p-2 flex items-center justify-center gap-2 ${buttonClassName}`}
        >
          <img alt="Mac" className="w-10 h-10" src={macIcon} />
          <img alt="download" className="w-8 h-8" src={svgDownloadIcon} />
        </div>
      </a>

      <a
        title="Download for Windows"
        className="w-full flex justify-evenly cursor-pointer bg-sky-100 items-center rounded-xl p-3"
        href={WINDOWS_APP_DOWNLOAD_URL}
      >
        <div
          className={`w-24 rounded-lg p-2 flex items-center justify-center gap-2 ${buttonClassName}`}
        >
          <img alt="Windows" className="w-10 h-10" src={windowsIcon} />
          <img alt="download" className="w-8 h-8" src={svgDownloadIcon} />
        </div>
      </a>
    </div>
  );
}
